const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: "https://cariuska.dev",
  //title: "TC",
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name: "Thiago Cariuska",
  role: "Back End Engineer",
  description:
    "Developer who works in search of results, with excellent communication skills, in addition to deep knowledge in several programming languages, able to learn quickly and apply new technologies.",
  resume: "https://cariuska.dev/Thiago-Cariuska-Resume.pdf",
  social: {
    linkedin: "https://www.linkedin.com/in/thiago-cariuska",
    github: "https://github.com/cariuska",
  },
};

const experience = [
  {
    name: "Tech Lead",
    description: "Analytics by Kaizen",
    stack: ["Porto, Portugal", "-", "11/2022 to Current"]
  },
  {
    name: "Senior Developer",
    description: "Analytics by Kaizen",
    stack: ["Porto, Portugal", "-", "10/2021 to 11/2022"]
  },
  {
    name: "Senior Developer",
    description: "Dock Banking as a Service",
    stack: ["São Paulo / Brasil", "-", "06/2019 to 10/2021"]
  },
  {
    name: "Systems Analysis and Development",
    description: "Virtual Soft Consultoria em Informática",
    stack: ["São Paulo / Brasil", "-", "08/2007 to 06/2019"]
  },
];

const educations = [
  {
    name: "Bachelor's Degree in Information Systems",
    description: "Faculdade Sumaré",
    stack: ["São Paulo / Brasil", "-", "12/2021"]
  },
];

const projects = [];
const projects_ant = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: "Project 1",
    description:
      "Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam",
    stack: ["SASS", "TypeScript", "React"],
    sourceCode: "https://github.com",
    livePreview: "https://github.com",
  },
  {
    name: "Project 2",
    description:
      "Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam",
    stack: ["SASS", "TypeScript", "React"],
    sourceCode: "https://github.com",
    livePreview: "https://github.com",
  },
  {
    name: "Project 3",
    description:
      "Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam",
    stack: ["SASS", "TypeScript", "React"],
    sourceCode: "https://github.com",
    livePreview: "https://github.com",
  },
];

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  ".Net Core",
  "C#",
  "Python",
  "JavaScript",
  "TypeScript",
  "React",
  "React Native",
  "Git",
  "CI/CD",
  "MySQL",
  "PostgreSQL",
  "Oracle",
  "Docker",
  "AWS",
  "GCP",
  "RabbitMQ",
  "Redis",
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "tcariuska@gmail.com",
};

export { header, about, projects, educations, experience, skills, contact };
